var interval;
$(document).on('turbolinks:load', function() {
    if ($('#day-one').length){
        interval = setInterval(check_secret, 60000);
        function check_secret(){
            Rails.ajax({
                url: "/day-one-secret",
                type: "post",
                data: "",
                success: function(data) {},
                error: function(data) {}
            })
        }
    }
    if ($('#smash-camp-store').length){
        interval = setInterval(check_secret, 60000);
        function check_secret(){
            Rails.ajax({
                url: "/store-open",
                type: "post",
                data: "",
                success: function(data) {},
                error: function(data) {}
            })
        }
    }
});

$(document).on("turbolinks:before-render", function() {
  clearInterval(interval);
});

import { Controller  } from 'stimulus';
import Trix from 'trix';

export default class extends Controller {
      static targets = ['editor'];

    attachEvent(event) {
            var eventId = event.target.value;
            event.target.selectedIndex = 0;
            fetch(`/events/${eventId}.json`)
              .then(response => response.json())
              .then(event => this._createAttachment(event))
            .catch(error => {
                console.log('error', error);
            });

    }

    attachResults(event) {
            var dayId = event.target.value;
            event.target.selectedIndex = 0;
            fetch(`/days/${dayId}.json`)
              .then(response => response.json())
              .then(event => this._createAttachment(event))
            .catch(error => {
                console.log('error', error);
            });
    }

    attachEmbed(event) {
            var embedId = event.target.value;
            event.target.selectedIndex = 0;
            fetch(`/embeds/${embedId}.json`)
              .then(response => response.json())
              .then(event => this._createAttachment(event))
            .catch(error => {
                console.log('error', error);
            });

    }

    _createAttachment(event) {
            const editor = this.editorTarget.editor;

        const attachment = new Trix.Attachment({
                  sgid: event.sgid,
                  content: event.content,

        });

            editor.insertAttachment(attachment);
            editor.insertString(' ');

    }

}

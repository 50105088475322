// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap"
import "@fortawesome/fontawesome-free/css/all"
import "@fortawesome/fontawesome-free/js/all"
import "trix"
import "@rails/actiontext"

Rails.start()
window.Rails = Rails;
Turbolinks.start()
ActiveStorage.start()

require("trix")
require("@rails/actiontext")
require("chosen-js")
require("select2")
require("custom/target-blank")
require("custom/secret.js")


// import "controllers"
import { Application  } from "stimulus"
import { definitionsFromContext  } from "stimulus/webpack-helpers"
import 'chosen-js/chosen.css'
import 'chosen-js'
import 'select2'
import 'bootstrap4c-chosen/dist/css/component-chosen.css'
import 'image-map-resizer'

import LocalTime from "local-time"
LocalTime.start()

const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

